<template>
  <div class="box">
    <div class="container">
      <h1 class="sat_left">
        <p>{{ $t("pageTitle.Balance") }}</p>
      </h1>
      <hr />
      <InviteCard :getProfile="getProfile"></InviteCard>
      <Balance :point="getProfile.coin" />
      <Trades />
      <UserTransactions :userId="getProfile.id" />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import Balance from "@/components/balance/Balance.vue";
import Trades from "@/components/balance/Trades.vue";
import UserTransactions from "@/components/balance/UserTransactions.vue";
import InviteCard from "@/components/balance/InviteCard.vue";

export default {
  metaInfo() {
    return {
      title: "Balance - TestAdmit"
    };
  },

  components: {
    Balance,
    Trades,
    UserTransactions,
    InviteCard
  },
  computed: {
    ...mapGetters("user", ["getProfile"])
  },
  mounted() {
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0px;
}
.balance {
  padding-top: 40px;
}
@media screen and (max-width: 500px) {
  ::v-deep .bb-intro-top{
    text-align: center;
  }
}
</style>
